import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import { FacebookProvider } from 'react-facebook';
import Layout from '../components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import BlogPost from '../components/BlogPost/BlogPost';
import CTAForm from 'components/CTAForm';

const fbAppId = process.env.GATSBY_FACEBOOK_APP_ID;

const propTypes = {
  data: PropTypes.object.isRequired,
};


class BlogPostTemplate extends React.Component {

  render() {
    const { data, location, pageContext } = this.props;
    return (
      <Layout
        type = 'standard'
        location={location}
      >
        <SEO
          title={data.mdx.frontmatter.titleTag || data.mdx.frontmatter.title}
          canonical={pageContext.canonicalURL}
          meta={data.mdx.frontmatter.metaTags}
        />
        <FacebookProvider appId={fbAppId}>
          <BlogPost data={data.mdx} />
          <CTAForm />
        </FacebookProvider>
      </Layout>
    );
  }
}
BlogPostTemplate.propTypes = propTypes;

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        path
        description
        date(formatString: "MMMM Do, YYYY")
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
        titleTag
        metaTags {
          name
          content
        }
      }
      body
    }
  }
`
