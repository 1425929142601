import React, { Component } from 'react';
import Img from 'gatsby-image';
import './BlogPost.scss';
import { Like, Comments } from 'react-facebook';
import ContentBox from 'components/Shared/ContentBox/ContentBox';

import { MDXRenderer } from 'gatsby-plugin-mdx';

const BlogHero = ({ data }) => (
  <div className="aaa-blogpost__hero">
    <Img
      className="aaa-blogpost__image"
      alt={data.title}
      fluid={data.heroImage.childImageSharp.fluid}
      loading="eager"
      fadeIn={false}
    />
  </div>
);

class BlogPost extends Component {
  constructor() {
    super();
    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    const { frontmatter: data, body } = this.props.data;
    return (
      <div className="aaa-blogpost">
        <BlogHero data={data} />
        <ContentBox>
          <div className="aaa-blogpost__social-links">
            {this.state.mounted && (
              <Like href={window.location.href} showFaces share />
            )}
          </div>
          <div>
            <h1 className="aaa-blogpost__title">{data.title}</h1>
            <div className="aaa-blogpost__date">{data.date}</div>
            <div className="aaa-blogpost__content">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </div>
          <div className="aaa-blogpost__social-links">
            {this.state.mounted && (
              <Comments href={window.location.href} width={'100%'} />
            )}
          </div>
        </ContentBox>
      </div>
    );
  }
}

export default BlogPost;
